import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import SunnySdkPage from "./Components/SunnySdk";
import { useAuth0 } from "@auth0/auth0-react";
import { PrivateRoute } from "./PrivateRoutes";
import { UnAuthenticatedRoutes } from "./UnAuthenticatedRoutes";
import TermsOfServices from "./Components/TermsOfService";
import { redirectOnLandingPageBasedOnHost, redirectOnHomePageBasedOnHost, openHomePageWidgetBasedOnEnv, extractSubdomain } from "./utils/Helpers/Functions";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const url = window.location.hostname
  const subdomain = extractSubdomain(url);
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const currentLang = document.documentElement.lang; 
      const iframe: any = document.getElementById('ifr1');
      if (!iframe || !iframe.contentWindow) {
        return;
      }
      const iframeBody = iframe.body;      
      if (iframeBody) {
        // OR remove all inline styles
        iframeBody.style.cssText = '';
      }
      iframe.contentWindow.postMessage(
        { type: 'LANG_CHANGE', lang: currentLang },
        openHomePageWidgetBasedOnEnv(subdomain)+"/" 
      );
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['lang'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
        }
      >
        {redirectOnHomePageBasedOnHost()}
      </Route>
      <Route
        element={
          <UnAuthenticatedRoutes
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
        }
      >
        {redirectOnLandingPageBasedOnHost()}
      </Route>
      <Route path="/sunnysdk" element={<SunnySdkPage />} />
      <Route path="/tos" element={<TermsOfServices />} />
    </Routes>
  );
}

export default App;
